/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Black.ttf");
  font-weight: 900;
}

/** Ionic CSS Variables **/
:root,
:root.light,
body.light {
  --ion-font-family: Arial, "Helvetica", sans-serif;

  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** ------------ AIMER COLORS ----------- **/

  --black: #000000;
  --bookmark: #118a60;
  --accent-white: #ffffff;
  --category-algorithms: #73667a;
  --category-general-guidance: #4d3ea2;
  --category-maternity-care: #b42199;
  --category-medical: #2ea100;
  --category-meds: #f7961e;
  --category-page-for-age: #e91581;
  --category-resuscitation: #00a9b8;
  --category-special-situations: #7b6f18;
  --category-trauma: #ed1d25;
  --category-default: #2c834f;
  --cta-warning: #ed242b;
  --cta: #007aff;
  --grey-00: #f8f9fa;
  --grey-10: #f1f3f5;
  --grey-20: #e8ecef;
  --grey-30: #dee2e6;
  --grey-40: #ced4da;
  --grey-50: #adb5bd;
  --grey-60: #868e96;
  --grey-70: #495057;
  --grey-80: #343a40;
  --grey-90: #212529;
  --icpg-blue-30: #23b8a8;
  --icpg-blue-50: #287b73;
  --icpg-blue-60: #005e55;
  --icpg-blue-70: #004745;
  --icpg-green: #036f49;
  --primary-40: #2ca972;
  --primary-50: #02704a;
  --primary-60: #016047;
  --white: #ffffff;
  --brand-green-light: #009f4d;
  --brand-green-dark: #007a53;
  --brand-cool-grey: #97999b;
  --green-dark: #007a53;
  --cool-grey: #97999b;
  --green-light: #009f4d;
  --yellow: #e1e000;
  --tab-bar: #fafcfb;
  --tab-bar-separator: #adbdb1;
  --grey-40: #ced4da;
  --sja-black: #363636;
  --dark-50: #6e6d6e;
  --background-primary: var(--grey-10);
  --background-secondary: var(--white);
  --text-00: var(--grey-00);
  --text-10: var(--grey-10);
  --text-20: var(--grey-20);
  --text-30: var(--grey-30);
  --text-40: var(--grey-40);
  --text-50: var(--grey-50);
  --text-60: var(--grey-60);
  --text-70: var(--grey-70);
  --text-80: var(--grey-80);
  --text-90: var(--grey-90);

  /* LIGHT MODE VARIABLES */
  /*  Header  */
  --header-background: var(--brand-green-dark);

  /* Tab Bar*/
  --tab-bar-background: #fafcfb;
  --tab-bar-selected: #009f4d;
  --tab-bar-border: #adbdb1;
  --background-tertiary: var(--grey-00);

  /* General */
  --page-background: #ffffff;
  --text-color: #000000;
  --separator: #d9dcde;

  /* Onboarding */
  --onboarding-primary-background: #363636;
  --onboarding-primary-text: #ffffff;
  --onboarding-secondary-background: #ffffff;

  /* Dashboard */
  --sja-background: url("../assets/Banner_Chevrons.svg");
  --disclaimer-background: #d82940;
  --dashboard-search-input: #ffffff;
  --search-button-background: #363636;
  --search-border: #363636;
  --search-placeholder: #97999b;
  --highlight-background: url("../assets/Image_Highlight_Chevrons.svg");
  --youtube-text: #009f4d;
  --empty-section: #6e6d6e;
  --bookmark-header: #007a53;

  /* Account */
  --logout-border: #d82940;
  --logout-fill: #ffffff;
  --logout-text: #d82940;
  --email-header: #6e6d6e;

  /* Dashboard Search */
  --recent-header: #f4f4f4;
  --recent-background: #ffffff;
  --recent-header-text: #494a4c;
  --recent-clear: #009f4d;
  --recent-text: #000000;
  --recent-chapter: #007a53;
  --recent-title: #000000;
  --recent-empty: rgba(0, 0, 0, 0.5);
  --recent-matches: #97999b;
  --recent-separator: #e7e8e9;

  /* Topics */
  --chapter-header: #009f4d;
  --contents-button: #009f4d;
  --topic-title-background: #009f4d;
  --grey-box: #ffffff;
  --green-box: #e8f3ec;
  --grey-box-border: #009f4d;
  --covid-background: #fdddd4;
  --covid-border: #670737;
  --covid-text: #670737;
  --covid-icon: url("../assets/Button_Covid@3x.png");
  --segment-background: rgba(118, 118, 128, 0.12);
  --segment-pill: #ffffff;
  --segment-pill-border: rgba(0, 0, 0, 0.04);
  --segment-text: #000000;

  /*  Menu  */
  --menu-background: var(--white);
  --menu-text: var(--black);
  --menu-separator: #b1b4b6;

  /* News */
  --news-latest-background: #009f4d;
  --news-date: #97999b;
  --news-title: #007a53;
  --news-subtitle: #363636;
}

@media (prefers-color-scheme: light) {
  body {
    /** ------------ AIMER COLORS ----------- **/
    --background-primary: var(--black);
    --background-secondary: var(--grey-90);
    --text-60: var(--grey-30);
    --text-70: var(--grey-20);
    --text-80: var(--grey-10);
    --text-90: var(--grey-00);
    --tab-bar: #1f2326;
    --tab-bar-separator: #adbdb1;

    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    /* LIGHT MODE VARIABLES */
    /*  Header  */
    --header-background: var(--brand-green-dark);

    /* Tab Bar*/
    --tab-bar-background: #fafcfb;
    --tab-bar-selected: #009f4d;
    --tab-bar-border: #adbdb1;
    --background-tertiary: var(--grey-00);

    /* General */
    --page-background: #ffffff;
    --text-color: #000000;
    --separator: #d9dcde;

    /* Onboarding */
    --onboarding-primary-background: #363636;
    --onboarding-primary-text: #ffffff;
    --onboarding-secondary-background: #ffffff;

    /* Dashboard  */
    --sja-background: url("../assets/Banner_Chevrons.svg");
    --disclaimer-background: #d82940;
    --dashboard-search-input: #ffffff;
    --search-button-background: #363636;
    --search-border: #363636;
    --search-placeholder: #97999b;
    --highlight-background: url("../assets/Image_Highlight_Chevrons.svg");
    --youtube-text: #009f4d;
    --empty-section: #6e6d6e;
    --bookmark-header: #007a53;

    /* Account */
    --logout-border: #d82940;
    --logout-fill: #ffffff;
    --logout-text: #d82940;
    --email-header: #6e6d6e;

    /* Dashboard Search */
    --recent-header: #f4f4f4;
    --recent-background: #ffffff;
    --recent-header-text: #494a4c;
    --recent-clear: #009f4d;
    --recent-text: #000000;
    --recent-chapter: #007a53;
    --recent-title: #000000;
    --recent-empty: rgba(0, 0, 0, 0.5);
    --recent-matches: #97999b;
    --recent-separator: #e7e8e9;

    /* Topics */
    --chapter-header: #009f4d;
    --contents-button: #009f4d;
    --topic-title-background: #009f4d;
    --grey-box: #ffffff;
    --green-box: #e8f3ec;
    --grey-box-border: #009f4d;
    --covid-background: #fdddd4;
    --covid-border: #670737;
    --covid-text: #670737;
    --covid-icon: url("../assets/Button_Covid@3x.png");
    --segment-background: rgba(118, 118, 128, 0.12);
    --segment-pill: #ffffff;
    --segment-pill-border: rgba(0, 0, 0, 0.04);
    --segment-text: #000000;

    /*  Menu  */
    --menu-background: var(--white);
    --menu-text: var(--black);
    --menu-separator: #b1b4b6;

    /* News */
    --news-latest-background: #009f4d;
    --news-date: #97999b;
    --news-title: #007a53;
    --news-subtitle: #363636;
  }
}

@media (prefers-color-scheme: dark) {
  /* change value back to dark to enable dark mode */
  /*
   * Dark Colors
   * -------------------------------------------
   */
  body {
    /* DARK MODE VARIABLES */
    /* Header */
    --header-background: #00574d;

    /* Tab Bar*/
    --tab-bar-background: #202123;
    --tab-bar-selected: #2baf78;
    --tab-bar-border: #494d57;
    --background-tertiary: var(--black);

    /* General */
    --page-background: #16171b;
    --text-color: #ffffff;
    --separator: #494a4c;

    /* Onboarding */
    --onboarding-primary-background: #ffffff;
    --onboarding-primary-text: #363636;
    --onboarding-secondary-background: #202125;

    /* Dashboard */
    --sja-background: url("../assets/Banner_Chevrons_Dark.svg");
    --disclaimer-background: #9b143f;
    --dashboard-search-input: #202125;
    --search-border: #494a4c;
    --search-button-background: #494a4c;
    --search-placeholder: #b8bbc1;
    --highlight-background: url("../assets/Image_Highlight_Chevrons_Dark.svg");
    --youtube-text: #2baf78;
    --empty-section: #e1e4eb;
    --bookmark-header: #2baf78;

    /* Account */
    --logout-border: #eb6e7f;
    --logout-fill: #202125;
    --logout-text: #eb6e7f;
    --email-header: #97999b;

    /* Dashboard Search */
    --recent-header: #16171b;
    --recent-background: #202125;
    --recent-header-text: #e1e4eb;
    --recent-clear: #2baf78;
    --recent-text: #ffffff;
    --recent-chapter: #2baf78;
    --recent-title: #ffffff;
    --recent-empty: rgba(255, 255, 255, 0.7);
    --recent-matches: #97999b;
    --recent-separator: #494a4c;

    /* Topics */
    --chapter-header: #2baf78;
    --contents-button: #2baf78;
    --topic-title-background: #007a53;
    --grey-box: #202125;
    --green-box: #202125;
    --grey-box-border: #2baf78;
    --covid-background: #9b143f;
    --covid-border: #8f0e37;
    --covid-text: #f7ecef;
    --covid-icon: url("../assets/Button_Covid_Dark@3x.png");
    --segment-background: rgba(118, 118, 128, 0.24);
    --segment-pill: #636366;
    --segment-pill-border: rgba(0, 0, 0, 0.04);
    --segment-text: #ffffff;

    /* Menu */
    --menu-background: var(--black);
    --menu-text: var(--white);
    --menu-separator: #494a4c;

    /* News */
    --news-latest-background: #007a53;
    --news-date: #97999b;
    --news-title: #56d794;
    --news-subtitle: #e1e4eb;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #9b143f;

    --ion-card-background: #1e1e1e;
  }
}

body.dark {
  /* DARK MODE VARIABLES */
  /* Header */
  --header-background: #00574d;

  /* Tab Bar*/
  --tab-bar-background: #202123;
  --tab-bar-selected: #2baf78;
  --tab-bar-border: #494d57;
  --background-tertiary: var(--black);

  /* General */
  --page-background: #16171b;
  --text-color: #ffffff;
  --separator: #494a4c;

  /* Onboarding */
  --onboarding-primary-background: #ffffff;
  --onboarding-primary-text: #363636;
  --onboarding-secondary-background: #202125;

  /* Dashboard */
  --sja-background: url("../assets/Banner_Chevrons_Dark.svg");
  --disclaimer-background: #9b143f;
  --dashboard-search-input: #202125;
  --search-border: #494a4c;
  --search-button-background: #494a4c;
  --search-placeholder: #b8bbc1;
  --highlight-background: url("../assets/Image_Highlight_Chevrons_Dark.svg");
  --youtube-text: #2baf78;
  --empty-section: #e1e4eb;
  --bookmark-header: #2baf78;

  /* Account */
  --logout-border: #eb6e7f;
  --logout-fill: #202125;
  --logout-text: #eb6e7f;
  --email-header: #97999b;

  /* Dashboard Search */
  --recent-header: #16171b;
  --recent-background: #202125;
  --recent-header-text: #e1e4eb;
  --recent-clear: #2baf78;
  --recent-text: #ffffff;
  --recent-chapter: #2baf78;
  --recent-title: #ffffff;
  --recent-empty: rgba(255, 255, 255, 0.7);
  --recent-matches: #97999b;
  --recent-separator: #494a4c;

  /* Topics */
  --chapter-header: #2baf78;
  --contents-button: #2baf78;
  --topic-title-background: #007a53;
  --grey-box: #202125;
  --green-box: #202125;
  --grey-box-border: #2baf78;
  --covid-background: #9b143f;
  --covid-border: #8f0e37;
  --covid-text: #f7ecef;
  --covid-icon: url("../assets/Button_Covid_Dark@3x.png");
  --segment-background: rgba(118, 118, 128, 0.24);
  --segment-pill: #636366;
  --segment-pill-border: rgba(0, 0, 0, 0.04);
  --segment-text: #ffffff;

  /* Menu */
  --menu-background: var(--black);
  --menu-text: var(--white);
  --menu-separator: #494a4c;

  /* News */
  --news-latest-background: #007a53;
  --news-date: #97999b;
  --news-title: #56d794;
  --news-subtitle: #e1e4eb;
}
