.navBar {
  --background: var(--header-background);
  background-color: var(--header-background);
  --border-radius: 0px !important;
  border-radius: 0px !important;
}

.iosHeader .navBar {
  --min-height: 44px !important;
  padding-top: max(var(--ion-statusbar-padding), var(--ion-safe-area-top)) !important;
}

.mdHeader .navBar {
  --min-height: 56px !important;
}

body {
  margin: 0px;
  font-family: "Arial" !important;
}

.loadingScreen {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: var(--header-background);
}

.loadingScreenText {
  color: var(--white);
  font-family: Inter, Helvetica;
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px;
}

.loadingScreenSpinner {
  --color: var(--white);
  width: 24px;
  height: 24px;
}

.toolbar-container {
  height: 44px !important;
}

#imageModal {
  --border-radius: 0px !important;
  border-radius: 0px !important;
}